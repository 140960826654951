import React from "react";
import PropTypes from "prop-types";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";

function DesignFullGrid({ props }) {
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Full Grid" />
      <h2>Full Width Grid</h2>
      <p className="design__text p2">
        Full width grids have 12 columns that are spaced equally across the
        width of the viewport, not including outside gutter spacing.
      </p>

      <div className="design__stories">
        <div className="design__story-section">
          <DesignStory
            component={LatticeGrid}
            type="demo-grid"
            className="demo-grid"
            props={props}
            subProps={{ type: "full-grid" }}>
            <span>1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
            <span>6</span>
            <span>7</span>
            <span>8</span>
            <span>9</span>
            <span>10</span>
            <span>11</span>
            <span>12</span>
          </DesignStory>
        </div>
      </div>
    </div>
  );
}

DesignFullGrid.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignFullGrid.defaultProps = {
  props: {},
};

export default DesignFullGrid;
